.contact-container {
    background-color: #FAD2B7;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.contact-baked-items img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.contact-baked-items {
    padding: 40px 40px 40px 20px;
}

.text-bubble {
    flex: 1;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.logo {
    margin: 0 10px;
    cursor: pointer;
}

.logo img {
    width: 40%; /* Set the width as needed */
    height: auto; /* Set the height as needed */
    object-fit: cover; /* Preserve aspect ratio while covering the container */
    transition: transform 0.3s ease-in-out;
}

.logo img:hover {
    transform: scale(1.1); /* Scale up the image on hover */
}

p, h1 {
    color: #964F37;
}

.welcome-section {
    color: #6A3526;
    font-weight: bold;
}

.welcome-section p {
    font-size: 2vw;
}

.logo-row {
    display: flex;
    justify-content: center;
}

.contact-blurb {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Styles for phones and tablets in portrait mode */
@media screen and (max-width: 1023px) and (orientation: portrait) {
    .contact-blurb-container {
        display: flex;
        flex-direction: column;
    }

    .contact-blurb,
    .contact-baked-items {
        width: 100%;
        box-sizing: border-box;
    }

    .contact-blurb p {
        font-size: 4vw;
        margin: 0;
    }

    .welcome-section p {
        font-size: 3vw;
    }
}

@media
/* Styles for phones and tablets in landscape mode */
screen and (max-width: 1023px) and (orientation: landscape),
/* Styles for laptops and larger screens */
screen and (min-width: 1024px) {
    .contact-blurb-container {
        display: flex;
        flex-direction: row;
    }

    .contact-blurb,
    .contact-baked-items {
        width: 50%;
        box-sizing: border-box;
    }

    .contact-blurb {
        padding: 40px 20px 40px 20px;
    }
}

/* Styles for phones and tablets in landscape mode */
@media screen and (max-width: 1023px) and (orientation: landscape) {
    .contact-blurb p {
        font-size: 1.15vw;
        margin: 0;
    }
}
/* Styles for laptops and larger screens */
@media screen and (min-width: 1024px) {
    .contact-blurb p {
        font-size: 1.45vw;
        margin: 0;
    }
}
