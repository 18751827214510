/* STYLES FOR ALL SCREENS */

.menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f7daba;
    color: #964F37;
}

.pdf-download-box h1 {
    color: #964F37
}

.pdf-download-box {
    display: inline-block;
    margin: 2em auto;
    padding: 1em;
    border: 2px solid #964F37;
}

.download-link {
    display: inline-block;
    padding: 0em 1em;
    color: #964F37;
    text-decoration: none;
    border: 2px solid #964F37;
    transition: background-color 0.3s ease;
}

.download-link:hover {
    background-color: #964F37;
    color: #f7daba;
}

.menu-image {
    height: auto;
    padding: 1em 0; /* This adds padding to the top and bottom of the images */
}

.menu-image:last-child {
    padding-bottom: 2em; /* This adds extra padding to the bottom of the last image */
}

/* Styles for phones and tablets in portrait mode */
@media screen and (max-width: 1023px) and (orientation: portrait) {
    .menu-image {
        width: 100%;
        height: auto;
    }
}

/* Styles for phones and tablets in landscape mode */
@media screen and (max-width: 1023px) and (orientation: landscape) {
    .menu-image {
        width: 75%;
        height: auto;
    }
}

/* Styles for laptops and larger screens */
@media screen and (min-width: 1024px) {
    .menu-image {
        width: 50%;
        height: auto;
    }
}
