/* STYLES FOR ALL SCREENS */

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F7DABA;
}

nav a {
  flex: 1;
  text-align: center;
  text-decoration: none;
  color: #964F37;
  font-weight: bold;
  font-size: 1.75em;
  transition: color 0.3s ease, transform 0.2s ease;
}

nav a:hover {
  color: #6A3526;
  transform: scale(1.1);
}


/* Styles for phones and tablets in portrait mode */
@media screen and (max-width: 1023px) and (orientation: portrait) {
  .logo {
    width: 100%;
    height: auto;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }

  nav a {
    font-size: 1.25em;
  }

  .instagram {
    width: 30px;
    height: auto;
  }
}

/* Styles for phones and tablets in landscape mode */
@media screen and (max-width: 1023px) and (orientation: landscape) {
  .logo {
    width: 169px;
    height: 90px;
    object-fit: cover;
    align-items: center;
  }

  .instagram {
    width: 50px;
    height: auto;
  }
}

/* Styles for laptops and larger screens */
@media screen and (min-width: 1024px) {
  .logo {
    width: 225px;
    height: 120px;
    object-fit: cover;
    align-items: center;
  }

  .instagram {
    width: 50px;
    height: auto;
  }
}
